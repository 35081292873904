<style>
	.el-tabs__header {
		background: #fff;
		padding: 0 20px;
	}
	.title {
		color: #fff;
		font-size: 14px;
	}
	.stdname {
		font-size: 13px;
		color: #333;
		margin: 0;
		margin-bottom: 6px;
	}
	.stdtips {
		font-size: 12px;
		color: #999;
		margin: 0;
	}
	.cat {
		box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
		padding: 12px 8px;
		text-align: left;
		color: #fff;
		background: url(../../assets/mask.png) no-repeat 100% 0;
		background-size: auto 100px;
		margin-bottom: 12px;
	}
	.cat p {
		text-shadow: 0 2px 12px rgb(0 0 0 / 30%);
	}
</style>
<template>
	<div style="background: linear-gradient(150deg, #2c6dd2 50%, #5eb361);min-height:100%;">
		<!-- <el-alert title="考试尚未开始，敬请期待" type="warning" style="margin-bottom:15px;" /> -->
		<div style="padding: 15px;">
			<el-row :gutter="12">
				<el-col :span="12">
					<div style="background-color: rgb(159, 134, 255);" class="cat" @click="change_paper_index(0)">
						<p style="font-size:14px;font-weight:600;margin:0">政策法规</p>
						<!-- <p style="font-size:12px;margin:0">（1）党和国家关于密码工作的方针政策</p>
					<p style="font-size:12px;margin:0">（2）密码法律法规与规范性文件</p>
					<p style="font-size:12px;margin:0">（3）涉及密码的网络安全相关法律法规</p>
					<p style="font-size:12px;margin:0">（4）密码应用政策文件</p> -->
						<p style="font-size:12px;margin:0">点击开始考试→</p>
					</div>
				</el-col>
				<el-col :span="12">
					<div style="background-color: rgb(0, 122, 225);" class="cat" @click="change_paper_index(1)">
						<p style="font-size:14px;font-weight:600;margin:0">密码技术</p>
						<!-- <p style="font-size:12px;margin:0">（5）密码学基础知识</p>
					<p style="font-size:12px;margin:0">（6）分组密码</p>
					<p style="font-size:12px;margin:0">（7）序列密码</p>
					<p style="font-size:12px;margin:0">（8）杂凑密码</p>
					<p style="font-size:12px;margin:0">（9）公钥密码</p>
					<p style="font-size:12px;margin:0">（10）实体鉴别协议</p>
					<p style="font-size:12px;margin:0">（11）密钥交换协议</p>
					<p style="font-size:12px;margin:0">（12）安全通信协议</p>
					<p style="font-size:12px;margin:0">（13）随机数技术</p>
					<p style="font-size:12px;margin:0">（14）密钥管理技术</p>
					<p style="font-size:12px;margin:0">（15）抗抵赖技术</p>
					<p style="font-size:12px;margin:0">（16）PKI技术</p>
					<p style="font-size:12px;margin:0">（17）身份认证技术</p> -->
						<p style="font-size:12px;margin:0">点击开始考试→</p>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="12">
				<el-col :span="12">
					<div style="background-color: rgb(61, 162, 214);" class="cat" @click="change_paper_index(2)">
						<p style="font-size:14px;font-weight:600;margin:0">密码产品</p>
						<!-- <p style="font-size:12px;margin:0">（18）智能密码钥匙</p>
					<p style="font-size:12px;margin:0">（19）智能IC卡</p>
					<p style="font-size:12px;margin:0">（20）POS/ATM密码应用系统</p>
					<p style="font-size:12px;margin:0">（21）PCI-E/PCI密码卡</p>
					<p style="font-size:12px;margin:0">（22）IPSec VPN网关</p>
					<p style="font-size:12px;margin:0">（23）SSL VPN网关</p>
					<p style="font-size:12px;margin:0">（24）安全认证网关</p>
					<p style="font-size:12px;margin:0">（25）密码键盘</p>
					<p style="font-size:12px;margin:0">（26）金融数据密码机</p>
					<p style="font-size:12px;margin:0">（27）服务器密码机</p>
					<p style="font-size:12px;margin:0">（28）签名验签服务器</p>
					<p style="font-size:12px;margin:0">（29）时间戳服务器</p>
					<p style="font-size:12px;margin:0">（30）安全门禁系统</p>
					<p style="font-size:12px;margin:0">（31）动态令牌/动态令牌认证系统</p>
					<p style="font-size:12px;margin:0">（32）安全电子签章系统</p>
					<p style="font-size:12px;margin:0">（33）电子文件密码应用系统</p>
					<p style="font-size:12px;margin:0">（34）可信计算密码支撑平台</p>
					<p style="font-size:12px;margin:0">（35）证书认证系统/证书认证密钥管理系统</p>
					<p style="font-size:12px;margin:0">（36）对称密钥管理产品</p>
					<p style="font-size:12px;margin:0">（37）安全芯片</p>
					<p style="font-size:12px;margin:0">（38）电子标签芯片</p>
					<p style="font-size:12px;margin:0">（39）智能IC卡密钥管理系统</p>
					<p style="font-size:12px;margin:0">（40）云服务器密码机</p>
					<p style="font-size:12px;margin:0">（41）随机数发生器</p>
					<p style="font-size:12px;margin:0">（42）可信密码模块</p>
					<p style="font-size:12px;margin:0">（43）区块链密码模块</p>
					<p style="font-size:12px;margin:0">（44）安全浏览器密码模块</p>
					<p style="font-size:12px;margin:0">（45）其他密码模块</p> -->
						<p style="font-size:12px;margin:0">点击开始考试→</p>
					</div>
				</el-col>
				<el-col :span="12">
					<div style="background-color: rgb(60, 197, 231);" class="cat" @click="change_paper_index(3)">
						<p style="font-size:14px;font-weight:600;margin:0">综合评测</p>
						<!-- <p style="font-size:12px;margin:0">（46）密码应用基本要求</p>
					<p style="font-size:12px;margin:0">（47）密码应用测评要求</p>
					<p style="font-size:12px;margin:0">（48）测评过程</p>
					<p style="font-size:12px;margin:0">（49）测评内容</p>
					<p style="font-size:12px;margin:0">（50）测评对象选取</p>
					<p style="font-size:12px;margin:0">（51）测评指标选择</p>
					<p style="font-size:12px;margin:0">（52）测评方法与实施</p>
					<p style="font-size:12px;margin:0">（53）测评工具使用</p>
					<p style="font-size:12px;margin:0">（54）量化评估</p>
					<p style="font-size:12px;margin:0">（55）风险分析</p>
					<p style="font-size:12px;margin:0">（56）报告编制</p>
					<p style="font-size:12px;margin:0">（57）测评案例分析</p>
					<p style="font-size:12px;margin:0">（58）方案密评</p> -->
						<p style="font-size:12px;margin:0">点击开始考试→</p>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="12">
				<el-col :span="12">
					<div style="background-color: rgb(109, 225, 225);" class="cat" @click="change_paper_index(4)">
						<p style="font-size:14px;font-weight:600;margin:0">密评实务综合</p>
						<!-- <p style="font-size:12px;margin:0">（59）基于上述基础知识和技能，对密码应用与安全性评估的理解、分析、实践、拓展等综合能力</p> -->
						<p style="font-size:12px;margin:0">点击开始考试→</p>
					</div>
				</el-col>
				<el-col :span="12">
					<div style="background-color: rgb(162, 229, 185);" class="cat" @click="change_paper_index(5)">
						<p style="font-size:14px;font-weight:600;margin:0">自定义模块</p>
						<!-- <p style="font-size:12px;margin:0">随机抽取以上59个考点,进行测试</p> -->
						<p style="font-size:12px;margin:0">点击开始考试→</p>
					</div>
				</el-col>
			</el-row>
		</div>
		<h4 class="title" style="padding:0 15px 15px 15px">排行榜(周)</h4>
		<div style="padding:0 15px 15px 20px">
			<el-row :gutter="12" style="width:100%;margin-bottom:12px;background:#fff;padding:10px 12px">
				<el-col :span="2">
					<h4 style="color:#fff;text-align:center;font-size:14px;background:#ee4b46">1</h4>
				</el-col>
				<el-col :span="6">
					<el-avatar :size="60" fit="cover" src="https://qsm.anmengsoft.com/zhead/DM_20230313230306_011.JPG" />
				</el-col>
				<el-col :span="16">
					<p style="margin:6px 0;">F*E <span style="float:right;color:#aaa;font-size:12px">最高 <span style="color:#2C6DD2;font-size:16px">99</span>分(答题14次)</span></p>
					<p style="margin:0;color:#aaa">2023年03月04日 13:24</p>
				</el-col>
			</el-row>
			<el-row :gutter="12" style="width:100%;margin-bottom:12px;background:#fff;padding:10px 12px">
				<el-col :span="2">
					<h4 style="color:#fff;text-align:center;font-size:14px;background:#ff8a06">2</h4>
				</el-col>
				<el-col :span="6">
					<el-avatar :size="60" fit="cover" src="https://qsm.anmengsoft.com/zhead/DM_20230313230306_012.JPG" />
				</el-col>
				<el-col :span="16">
					<p style="margin:6px 0;">S*y <span style="float:right;color:#aaa;font-size:12px">最高 <span style="color:#2C6DD2;font-size:16px">98</span>分(答题10次)</span></p>
					<p style="margin:0;color:#aaa">2023年03月04日 13:23</p>
				</el-col>
			</el-row>
			<el-row :gutter="12" style="width:100%;margin-bottom:12px;background:#fff;padding:10px 12px">
				<el-col :span="2">
					<h4 style="color:#fff;text-align:center;font-size:14px;background:#eeb56e">3</h4>
				</el-col>
				<el-col :span="6">
					<el-avatar :size="60" fit="cover" src="https://qsm.anmengsoft.com/zhead/DM_20230313230306_017.JPG" />
				</el-col>
				<el-col :span="16">
					<p style="margin:6px 0;">s*e <span style="float:right;color:#aaa;font-size:12px">最高 <span style="color:#2C6DD2;font-size:16px">98</span>分(答题13次)</span></p>
					<p style="margin:0;color:#aaa">2023年03月04日 13:23</p>
				</el-col>
			</el-row>
			<el-row :gutter="12" style="width:100%;margin-bottom:12px;background:#fff;padding:10px 12px">
				<el-col :span="2">
					<h4 style="color:#FAAD14;text-align:center;font-size:14px">4</h4>
				</el-col>
				<el-col :span="6">
					<el-avatar :size="60" fit="cover" src="https://qsm.anmengsoft.com/zhead/DM_20230313230306_024.JPG" />
				</el-col>
				<el-col :span="16">
					<p style="margin:6px 0;">小*警 <span style="float:right;color:#aaa;font-size:12px">最高 <span style="color:#2C6DD2;font-size:16px">98</span>分(答题15次)</span></p>
					<p style="margin:0;color:#aaa">2023年03月04日 13:24</p>
				</el-col>
			</el-row>
			<el-row :gutter="12" style="width:100%;margin-bottom:12px;background:#fff;padding:10px 12px">
				<el-col :span="2">
					<h4 style="color:#FAAD14;text-align:center;font-size:14px">5</h4>
				</el-col>
				<el-col :span="6">
					<el-avatar :size="60" fit="cover" src="https://qsm.anmengsoft.com/zhead/DM_20230313230306_019.JPG" />
				</el-col>
				<el-col :span="16">
					<p style="margin:6px 0;">云** <span style="float:right;color:#aaa;font-size:12px">最高 <span style="color:#2C6DD2;font-size:16px">96</span>分(答题14次)</span></p>
					<p style="margin:0;color:#aaa">2023年03月04日 13:24</p>
				</el-col>
			</el-row>
			<el-row :gutter="12" style="width:100%;margin-bottom:12px;background:#fff;padding:10px 12px">
				<el-col :span="2">
					<h4 style="color:#FAAD14;text-align:center;font-size:14px">6</h4>
				</el-col>
				<el-col :span="6">
					<el-avatar :size="60" fit="cover" src="https://qsm.anmengsoft.com/zhead/DM_20230313230306_020.JPG" />
				</el-col>
				<el-col :span="16">
					<p style="margin:6px 0;">香*皮 <span style="float:right;color:#aaa;font-size:12px">最高 <span style="color:#2C6DD2;font-size:16px">95</span>分(答题12次)</span></p>
					<p style="margin:0;color:#aaa">2023年03月04日 13:24</p>
				</el-col>
			</el-row>
			<el-row :gutter="12" style="width:100%;margin-bottom:12px;background:#fff;padding:10px 12px">
				<el-col :span="2">
					<h4 style="color:#FAAD14;text-align:center;font-size:14px">7</h4>
				</el-col>
				<el-col :span="6">
					<el-avatar :size="60" fit="cover" src="https://qsm.anmengsoft.com/zhead/DM_20230313230306_021.JPG" />
				</el-col>
				<el-col :span="16">
					<p style="margin:6px 0;">F** <span style="float:right;color:#aaa;font-size:12px">最高 <span style="color:#2C6DD2;font-size:16px">95</span>分(答题13次)</span></p>
					<p style="margin:0;color:#aaa">2023年03月04日 13:24</p>
				</el-col>
			</el-row>
			<el-row :gutter="12" style="width:100%;margin-bottom:12px;background:#fff;padding:10px 12px">
				<el-col :span="2">
					<h4 style="color:#FAAD14;text-align:center;font-size:14px">8</h4>
				</el-col>
				<el-col :span="6">
					<el-avatar :size="60" fit="cover" src="https://qsm.anmengsoft.com/zhead/DM_20230313230306_022.JPG" />
				</el-col>
				<el-col :span="16">
					<p style="margin:6px 0;">B*z <span style="float:right;color:#aaa;font-size:12px">最高 <span style="color:#2C6DD2;font-size:16px">95</span>分(答题15次)</span></p>
					<p style="margin:0;color:#aaa">2023年03月04日 13:24</p>
				</el-col>
			</el-row>
			<el-row :gutter="12" style="width:100%;margin-bottom:12px;background:#fff;padding:10px 12px">
				<el-col :span="2">
					<h4 style="color:#FAAD14;text-align:center;font-size:14px">9</h4>
				</el-col>
				<el-col :span="6">
					<el-avatar :size="60" fit="cover" src="https://qsm.anmengsoft.com/zhead/DM_20230313230306_023.JPG" />
				</el-col>
				<el-col :span="16">
					<p style="margin:6px 0;">敬*男 <span style="float:right;color:#aaa;font-size:12px">最高 <span style="color:#2C6DD2;font-size:16px">91</span>分(答题4次)</span></p>
					<p style="margin:0;color:#aaa">2023年03月04日 13:24</p>
				</el-col>
			</el-row>
			<el-row :gutter="12" style="width:100%;margin-bottom:12px;background:#fff;padding:10px 12px">
				<el-col :span="2">
					<h4 style="color:#FAAD14;text-align:center;font-size:14px">10</h4>
				</el-col>
				<el-col :span="6">
					<el-avatar :size="60" fit="cover" src="https://qsm.anmengsoft.com/zhead/DM_20230313230306_018.JPG" />
				</el-col>
				<el-col :span="16">
					<p style="margin:6px 0;">L*a <span style="float:right;color:#aaa;font-size:12px">最高 <span style="color:#2C6DD2;font-size:16px">88</span>分(答题12次)</span></p>
					<p style="margin:0;color:#aaa">2023年03月04日 13:24</p>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				paper_index: 0,
				// rate_max: 5,
				// rate_colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
				// rate_texts: ["了解", "了解", "熟悉", "熟悉", "重点掌握"],
			};
		},
		mounted() {
			document.title = "密评模考";
		},
		methods: {
			change_paper_index(uuid) {
				this.paper_index = uuid;
				if (window.localStorage.getItem("token")) {
					this.$router.push({
						path: "/exam/" + uuid,
					});
				} else {
					this.$router.push({
						path: "/login",
						query: {
							uuid,
						},
					});
				}
				// Message({
				// 	showClose: true,
				// 	message: "考试尚未开始，敬请期待",
				// 	type: "warning",
				// });
			},

			show_pdf(pdf_url) {
				this.$router.push({
					path: "/show_pdf",
					query: {
						pdf: pdf_url,
					},
				});
			},
		},
	};
</script>